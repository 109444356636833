import React from 'react';
import { graphql } from 'gatsby';
import PricingPage from 'components/pages/PricingPage';

const seo = {
  title: 'Q°emotion – Tarifas y suscripciones',
  keywords: [],
  description:
    'Pague poco y gane mucho. Mejore la experiencia de sus clientes con Q°emotion a un precio ajustado a sus necesidades. Suscripción mensual o anual. Prueba gratuita.',
};

export default props => <PricingPage seo={seo} {...props} />;

export const pageQuery = graphql`
  query {
    dataSyntheticPricing: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "pricing" }
          blockType: { eq: "syntheticPricingBlock" }
          lang: { eq: "es" }
          name: { ne: "Emotion Plus" }
        }
      }
    ) {
      nodes {
        frontmatter {
          index
          name
          benchmarkCount
          billedAnnualPrice
          categoriesMax
          commentsPerMonth
          commentsPerYear
          customerJourney
          dataCollection
          isCorporate
          liveSupport
          pricePerComment
          supportPriority
          reportingCount
          userCount
          annualPrice
          monthlyPrice
        }
      }
    }
    dataDetailedPricing: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "pricing" }
          blockType: { eq: "detailedPricingBlock" }
          lang: { eq: "es" }
          name: { ne: "Emotion Plus"}
        }
      }
    ) {
      nodes {
        frontmatter {
          index
          name
          pricePerComment
          monthlyPrice
          annualPrice
          billedAnnualPrice
          commentsPerYear
          commentsPerMonth
          categoriesMax
          customerJourney
          dataCollection
          automatedScheduler
          generalModules
          customerJourneyModule
          emotionalMapping
          alertModule
          insightModule
          benchmarkCount
          reportingCount
          userCount
          resultsDelivery
          fileImport
          fileSpecs
          yourSurveys
          tripadvisor
          googleMyBusiness
          amazonReviews
          otherWebReviews
          zendesk
          emails
          chatConversations
          socialNetworks
          blogs
          liveSupport
          helpCenter
          ticketsPriority
        }
      }
    }
    dataQuestionsFaqBlock: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "pricing" }
          blockType: { eq: "faqBlock" }
          lang: { eq: "es" }
          index: { gte: 1 }
        }
      }
      sort: { fields: [frontmatter___index] }
    ) {
      nodes {
        rawMarkdownBody
        html
        frontmatter {
          title
        }
      }
    }
    dataFooterFaqBlock: markdownRemark(
      frontmatter: {
        pageType: { eq: "pricing" }
        blockType: { eq: "faqBlock" }
        lang: { eq: "es" }
        index: { eq: 0 }
      }
    ) {
      html
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "pricing" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "es" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
  }
`;
